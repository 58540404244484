"use client";

import { Toaster } from "@/components/ui/toaster";
import { toast } from "@/components/ui/use-toast";
import useCartStore from "@/stores/CartStore";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/navigation";

interface Variant {
  id: number;
  product_id: number;
  option_id: number;
  price: number;
  discounted_price: number | null;
  usd_price: number;
  discounted_usd_price: number | null;
  stock: number;
  sku: string;
  grams: number;
  thumbnail_url: string;
  images: string[] | null;
  option: {
    id: number;
    variation_id: number;
    value: string;
    hex_code: string;
    variation: {
      id: number;
      product_id: number;
      name: string;
    };
  };
}

interface ProductInterface {
  id: number;
  name: string;
  description: string;
  price: number;
  usd_price: number;
  discounted_price: number | null;
  discounted_usd_price: number | null;
  stock: number;
  category_id: number;
  thumbnail_url: string;
  type: "subscription" | "variant" | "single";
  slug: string;
  sku: string | null;
  grams: number | null;
  images: string[] | null;
  variants?: Variant[];
}

interface ProductCardProps {
  product: any;
}

export default function ProductCard({ product }: ProductCardProps) {
  const { addToCart } = useCartStore();
  const router = useRouter();

  const firstVariant =
    product.type === "variant" &&
    product.variants &&
    product.variants.length > 0
      ? product.variants[0]
      : null;

  const displayPrice = firstVariant
    ? firstVariant.discounted_price || firstVariant.price
    : product.discounted_price || product.price;

  const originalPrice = firstVariant ? firstVariant.price : product.price;

  const handleAddProduct = () => {
    if (product.type === "variant" && firstVariant) {
      addToCart(
        {
          ...product,
          price: firstVariant.price,
          discounted_price: firstVariant.discounted_price,
          stock: firstVariant.stock,
          sku: firstVariant.sku,
          grams: firstVariant.grams,
          variant_id: firstVariant.id,
          thumbnail_url: firstVariant.thumbnail_url || product.thumbnail_url,
          variant: {
            id: firstVariant.id,
            name: firstVariant.option.variation.name,
            value: firstVariant.option.value,
          },
        },
        1
      );
    } else {
      addToCart(product, 1);
    }

    toast({
      title: "Producto añadido al carrito",
      image: product.thumbnail_url,
      description: `${1} x ${product.name} añadido al carrito.`,
      duration: 3000,
    });
  };

  const formatPrice = (price: number) => {
    return price.toLocaleString("es-CL", {
      style: "currency",
      currency: "CLP",
    });
  };

  return (
    <>
      <div className="flex flex-col border border-gray-200 rounded-md">
        <Link href={"/products/" + product.slug}>
          <Image
            src={firstVariant?.thumbnail_url || product.thumbnail_url}
            alt={product.name}
            className="w-full max-h-80 object-cover"
            width={300}
            height={192}
          />
        </Link>
        <div className="p-4">
          <div className="text-center">
            <Link href={"/products/" + product.slug}>
              <h3 className="text-lg font-semibold text-black mt-2">
                {product.name}
              </h3>
            </Link>
            <p className="text-gray-500 mt-1">{formatPrice(displayPrice)}</p>
            {displayPrice !== originalPrice && (
              <p className="text-xs text-bright-pink line-through">
                {formatPrice(originalPrice)}
              </p>
            )}
          </div>
          <div>
            {product.stock > 0 || (firstVariant && firstVariant.stock > 0) ? (
              product.type === "variant" ? (
                <button
                  onClick={() => router.push(`/products/${product.slug}`)}
                  className="border border-black text-black w-full mt-2 p-2 rounded-full font-medium"
                >
                  ELEGIR OPCIONES
                </button>
              ) : (
                <button
                  onClick={handleAddProduct}
                  className="border border-black text-black w-full mt-2 p-2 rounded-full font-medium"
                >
                  AÑADIR AL CARRITO
                </button>
              )
            ) : (
              <button
                disabled
                className="border border-gray-300 text-gray-300 w-full mt-2 p-2 rounded-full font-medium cursor-not-allowed"
              >
                SIN STOCK
              </button>
            )}
          </div>
        </div>
        <Toaster />
      </div>
    </>
  );
}
